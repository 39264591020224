import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { converter } from 'culori';

interface HeyThemeContext {
  color_primary: string | null
  color_neutral: string | null
  color_content: string | null
  setTheme: (color_primary: string, color_neutral: string, color_content: string) => void
  style: any
}

export const HeyThemeContext = createContext<HeyThemeContext>({
  color_primary: null,
  color_neutral: null,
  color_content: null,
  setTheme() {},
  style: null,
})

export function HeyThemeProvider({ children }: { children: React.ReactNode }) {
  const [color_primary, setColor_primary] = useState<string | null>(null)
  const [color_neutral, setColor_neutral] = useState<string | null>(null)
  const [color_content, setColor_content] = useState<string | null>(null)

  const setTheme = useCallback((color_primary: string, color_neutral: string, color_content: string): void => {
    setColor_primary(color_primary)
    setColor_neutral(color_neutral)
    setColor_content(color_content)
  }, [setColor_primary, setColor_neutral, setColor_content])

  const style = useMemo(() => {
    const oklch = converter('oklch')

    if (!(color_primary && color_neutral && color_content)) {
      return null
    }

    function prepareColor(color: string) {
      if (color[0] != '#') {
        color = '#' + color
      }

      if (color.toLowerCase() === '#000000') {
        color = '#000001'
      }

      if (color.toLowerCase() === '#ffffff') {
        color = '#FFFFFE'
      }

      return color
    }

    function culoriToString(color: any) {
      return `${color.l} ${color.c} ${color.h}`
    }

    // Something like that should work
    // // const primary = prepareColor(color_primary)
    // const neutral = oklch(prepareColor(color_neutral))!
    // // const content = oklch(prepareColor(color_content))!
    //
    // return `
    //   :root {
    //     --fallback-p: #${color_primary};
    //     --fallback-n: #${color_neutral};
    //     --fallback-pc: #${color_neutral};
    //     --s: ${culoriToString({ ...neutral, l: 0.95*neutral.l })};
    //     --fallback-bc: #${color_content};
    //   }

    const primary = oklch(prepareColor(color_primary))!
    const neutral = oklch(prepareColor(color_neutral))!
    const content = oklch(prepareColor(color_content))!
    const neutralLight = neutral.l < 0.2 ? 0.2 : 0.95 * neutral.l

    return `
      :root {
        --p: ${culoriToString(primary)};
        --n: ${culoriToString(neutral)};
        --pc: ${culoriToString(neutral)};
        --s: ${culoriToString({ ...neutral, l: neutralLight })};
        --bc: ${culoriToString(content)};
        --b1: ${culoriToString({ ...neutral, l: neutralLight })};
      }
    `

  }, [color_primary, color_neutral, color_content]);

  return <HeyThemeContext.Provider value={{
    color_primary,
    color_neutral,
    color_content,
    setTheme,
    style,
  }}>
    { children }
  </HeyThemeContext.Provider>
}

export function useHeyTheme() {
  return useContext(HeyThemeContext)
}
