import { Badge, Button, Checkbox, Collapse, FileInput, Input, Loading, Modal, Textarea } from 'react-daisyui'
import { useProfile } from '@/stores/useAuth.ts'
import { useRef, useState } from 'react'
import { useClient } from '@/stores/useClient.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useDrives } from '@/stores/useDrives.ts'
import { IconBrandWhatsapp, IconPlus, IconTrash } from '@tabler/icons-react'
import { Album } from '@/models/album.ts'
import useCollapses from '@/hooks/useCollapses.tsx'
import AlbumSelection from '@/components/AlbumSelection.tsx'
import { useHeyTheme } from '@/contexts/heyTheme.tsx'
import { HeyDrive, HeyDriveType } from '@/models/drive.ts'
import { ChromePicker } from 'react-color'
import DriveHeader from '@/components/DriveHeader.tsx'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { defaultThemes, ThemeDetails } from '@/components/Theme/ThemeSelection.tsx'
import ThemeColors from '@/components/Theme/ThemeColors.tsx'
import QRCodeDesigner, { QRCodeShape } from '@/components/QRCodeDesigner.tsx'
import { captureException } from '@/controller/sentryHelper.ts'
import { getDriveDefaultsFor } from '@/controller/driveDefaults.ts'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { getCurrentLanguageInfos } from '@/controller/languageHelper.ts'
import { FullDownload } from '@/models/fullDownload.ts'
import { AxiosError } from 'axios'
import { FullDownloadRequest, FullDownloadRequestStatus } from '@/models/fullDownloadRequest.ts'

export default function SettingsPage() {
  const { driveId } = useParams<{ driveId: string }>()
  const { drives  } = useDrives()
  const drive = drives[driveId!]!
  const {profile} = useProfile()
  const { currentAlbum } = useFilesContext()!
  const imageModalRef = useRef<HTMLDialogElement>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [isDeletingImage, setIsDeletingImage] = useState(false)
  const { updateAlbum } = useClient()
  const queryClient = useQueryClient()

  if (!profile) {
    return <>Loading</>
  }

  const isDriveOwner = profile!.username === drive.author

  function onChangeImageClick() {
    imageModalRef.current?.showModal()
  }

  function onImageUploadClick() {
    console.log('click', imageInputRef.current)
    imageInputRef.current!.click()
  }

  async function onImageFileSelect(e: any) {
    console.log('ay', e)
    if (isUploadingFile) {
      return
    }

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setIsUploadingFile(true)
      try {
        const data = new FormData()
        data.append('thumbnail', file)
        await updateAlbum(currentAlbum!.id, data)
        await queryClient.invalidateQueries({
          queryKey: ['drive', drive.id],
        })
      } catch (e) {
        alert('Error: ' + JSON.stringify(e))
        console.error('error uploading new image', e)
      } finally {
        setIsUploadingFile(false)
        imageModalRef.current?.close()
      }
    }
  }

  async function onDeleteImageClick() {
    if (isDeletingImage) {
      return
    }

    if (confirm(t('common.deleteRly'))) {
      setIsDeletingImage(true)
      try {
        await updateAlbum(currentAlbum!.id, { thumbnail: null })
        await queryClient.invalidateQueries({
          queryKey: ['drive', drive.id],
        })
      } catch (e) {
        alert('Error: ' + JSON.stringify(e))
        console.error('error deleting image', e)
      } finally {
        setIsDeletingImage(false)
        imageModalRef.current?.close()
      }
    }
  }

  return <>
    <DriveHeader onChangeImageClick={onChangeImageClick} />
    <div className='container max-w-[40rem] mx-auto px-4 flex flex-col items-center gap-2'>
      <ProfileSettings />
      { isDriveOwner && currentAlbum && drive && <DriveSettings /> }
    </div>
    <input type='file' ref={imageInputRef} className='hidden' accept='image/jpeg,image/png' onInput={onImageFileSelect} />
    <Modal ref={imageModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {t('common.changeImage')}
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5" disabled={isUploadingFile || isDeletingImage}>✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col items-center gap-3'>
          <Button disabled={isUploadingFile || isDeletingImage} loading={isUploadingFile} color='primary' onClick={onImageUploadClick}>
            {t('common.uploadImage')}
          </Button>
          <Button disabled={isUploadingFile || isDeletingImage || currentAlbum?.thumbnail == null} loading={isDeletingImage} color='ghost' size='sm' onClick={onDeleteImageClick}>
            {t('common.removeImage')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  </>
}

function DriveSettings() {
  const { driveId } = useParams<{ driveId: string }>()
  const { drives } = useDrives()
  const drive = drives[driveId!]!
  const { currentAlbum: selectedAlbum } = useFilesContext()!
  const { updateDrive, updateAlbum, createAlbum, deleteAlbum } = useClient()
  const queryClient = useQueryClient()

  // const { albums } = useAlbums()
  const [driveTitle, setDriveTitle] = useState(drive.title)
  const [customLogo, setCustomLogo] = useState<File | null>(null)
  const [isSavingGeneral, setIsSavingGeneral] = useState(false)
  const [isSavingAlbum, setIsSavingAlbum] = useState(false)
  const [isDeletingAlbum, setIsDeletingAlbum] = useState(false)
  const [currentAlbum, setCurrentAlbum] = useState(drive.albums.find(album => selectedAlbum && album.id === selectedAlbum.id) || drive.albums.find(album => album.is_primary) || drive.albums[0])
  const [isCreatingNewAlbum, setIsCreatingNewAlbum] = useState(false)
  const { collapse: outerCollapse, selectedCollapse: selectedOuterCollapse } = useCollapses()

  async function onGeneralSaveClick() {
    if (isSavingGeneral) {
      return
    }

    setIsSavingGeneral(true)
    try {
      const data = new FormData()
      data.append('title', driveTitle)
      if (customLogo) {
        data.append('custom_logo', customLogo)
      }

      await updateDrive(drive.id, data)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e) {
      alert('Error saving: ' + JSON.stringify(e))
      console.error(e)
    } finally {
      setIsSavingGeneral(false)
    }
  }

  function formDataForAlbumPartial(albumData: Partial<Album>) {
    const data = new FormData()
    data.append('title', albumData.title!)
    data.append('description', albumData.description!)
    data.append('is_private', albumData.is_private ? 'true' : 'false')
    data.append('is_upload_disabled', albumData.is_upload_disabled ? 'true' : 'false')
    data.append('is_unlisted', albumData.is_unlisted ? 'true' : 'false')
    data.append('is_games_disabled', albumData.is_games_disabled ? 'true' : 'false')
    if (albumData.thumbnail !== undefined) {
      data.append('thumbnail', albumData.thumbnail!)
    }

    return data
  }

  function onCustomLogoSelect(e: any) {
    if (!e.target.files || !e.target.files[0]) {
      setCustomLogo(null)
    } else {
      setCustomLogo(e.target.files[0])
    }
  }

  async function onAlbumSaveClick(album: Album, albumData: Partial<Album>) {
    if (isSavingAlbum) {
      return
    }

    setIsSavingAlbum(true)
    try {
      const updatedData = formDataForAlbumPartial(albumData)

      await updateAlbum(album.id, updatedData)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e) {
      alert('Error saving: ' + JSON.stringify(e))
      console.error(e)
    } finally {
      setIsSavingAlbum(false)
    }
  }

  async function onAlbumDeleteConfirm(album: Album) {
    if (isDeletingAlbum) {
      return
    }

    setIsDeletingAlbum(true)
    try {

      await deleteAlbum(album.id)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
      // Fallbach after deleting
      setIsCreatingNewAlbum(true)
    } catch (e: any) {
      console.error('error deleting', e)
      if (e && e.response && e.response.status === 409) {
        alert(t('error.stillImages'))
      } else {
        alert('Error deleting: ' + JSON.stringify(e))
      }
    } finally {
      setIsDeletingAlbum(false)
    }
  }

  async function onAlbumCreate(albumData: Partial<Album>) {
    if (isSavingAlbum) {
      return
    }

    try {
      const data = formDataForAlbumPartial(albumData)

      const newAlbumRes = await createAlbum(drive.id, data)
      const newAlbum: Album = newAlbumRes.data
      console.log('newAlbum', newAlbum)
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
      // Not working yet idk why
      // setCurrentAlbum(newAlbum)
    } catch (e) {
      alert('Error creating album: ' + JSON.stringify(e))
      console.error(e)
    } finally {
      setIsSavingAlbum(false)
    }
  }

  return <>
    <h2 className='text-3xl font-serif my-4 mb-5'>
      {t('common.settings')}
    </h2>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('general').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('general').onClick}>
        {t('common.generalSettings')}
      </Collapse.Title>
      <Collapse.Content>
        <div className='flex flex-col gap-3'>
          {
            drive.drive_type === HeyDriveType.COMPANY && <>
              <label className="form-control w-full max-w-xs">
                <span className="label-text">{t('common.driveLogo')}</span>
                <FileInput className='w-full' accept='image/jpeg,image/png,image/svg+xml' onInput={onCustomLogoSelect} />
              </label>
            </>
          }
          <label className="form-control w-full max-w-xs">
            <span className="label-text">{t('common.eventName')}</span>
            <Textarea value={driveTitle} onChange={e => setDriveTitle(e.target.value)}/>
          </label>
          <p>
            {t('common.moreSettingsAlbum')}
          </p>
          <Button disabled={isSavingGeneral} loading={isSavingGeneral} className='mt-5' color='primary'
                  onClick={onGeneralSaveClick}>{t('common.save')}</Button>
        </div>
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('theme').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('theme').onClick}>
      {t('common.colors')}
      </Collapse.Title>
      <Collapse.Content>
        <ThemeSettings drive={drive} />
      </Collapse.Content>
    </Collapse>


    <Collapse className='collapse-arrow bg-secondary w-full' open={outerCollapse('albums').open}>
      <Collapse.Title className='font-semibold w-full' onClick={outerCollapse('albums').onClick}>
        {t('common.albums')}
      </Collapse.Title>
      <Collapse.Content>
        <div className='flex flex-col w-[80vw] max-w-[36rem] gap-3'>
          <h3 className='text-xl font-semibold'>{t('common.albums')}</h3>
          <AlbumSelection
            drive={drive}
            selectedAlbum={isCreatingNewAlbum ? null : currentAlbum}
            setSelectedAlbum={(a) => {
              setIsCreatingNewAlbum(false)
              setCurrentAlbum(a)
            }}
            showScrollbar
            prependChild={(
              <div className='overflow-hidden inline-flex flex-col w-[6rem] mr-3 items-center'
                   onClick={() => setIsCreatingNewAlbum(true)}>
                <div
                  className='rounded-full w-[4rem] h-[4rem] mb-2 bg-primary inline-flex items-center justify-center relative'>
                  <IconPlus className='m-0 p-0 text-neutral absolute' size={40}/>
                </div>
                <div className={
                  'rounded-xl text-center text-sm px-2 w-full py-1 border-primary overflow-hidden overflow-ellipsis whitespace-nowrap ' + (isCreatingNewAlbum ? 'bg-primary text-neutral' : '')
                }>
                  {t('common.new')}
                </div>
              </div>
            )}
          />
        </div>
        <div className='flex flex-col mt-5'>
          {
          isCreatingNewAlbum && <>
              <h3 className='text-xl font-semibold'>{t('common.createNewAlbum')}</h3>
              <AlbumEdit
                onSubmit={onAlbumCreate}
                album={{
                  title: '',
                  description: '',
                }}
                isSaving={isSavingAlbum}
              />
            </>
          }
          {
            !isCreatingNewAlbum && currentAlbum &&  <>
              <h3 className='text-xl font-semibold'>{t('common.editAlbum')}</h3>
              <AlbumEdit
                originalAlbum={currentAlbum}
                key={currentAlbum.id}
                onSubmit={data => onAlbumSaveClick(currentAlbum, data)}
                album={currentAlbum}
                isSaving={isSavingAlbum}
                isDeleting={isDeletingAlbum}
                onDeleteConfirm={onAlbumDeleteConfirm}
              />
            </>
          }
        </div>
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('qrcode').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('qrcode').onClick}>
        {t('common.qrCode')}
      </Collapse.Title>
      <Collapse.Content>
        <QRCodeSettings drive={drive} />
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('liveshow').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('liveshow').onClick}>
        {t('common.liveshow')}
      </Collapse.Title>
      <Collapse.Content>
        <Liveshow drive={drive} />
      </Collapse.Content>
    </Collapse>

    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('download').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('download').onClick}>
        {t('common.fullDownload')}
      </Collapse.Title>
      <Collapse.Content>
        <FullDownloadSection drive={drive} open={selectedOuterCollapse === 'download'} />
      </Collapse.Content>
    </Collapse>


    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('help').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('help').onClick}>
        {t('common.helpSupport')}
      </Collapse.Title>
      <Collapse.Content>
        <HelpSupport drive={drive} />
      </Collapse.Content>
    </Collapse>
  </>
}

function AlbumEdit({ onSubmit, album, isSaving, isDeleting, onDeleteConfirm, originalAlbum }: { onSubmit: (album: Partial<Album>) => void, album: Partial<Album>, isSaving: boolean, originalAlbum?: Album, isDeleting?: boolean, onDeleteConfirm?: (originalAlbum: Album) => void }) {
  const { collapse: deleteAlbumCollapse} = useCollapses()
  const deleteModalRef = useRef<HTMLDialogElement>(null)
  const [albumData, setAlbumData] = useState<Partial<Album>>({
    title: album.title,
    description: album.description,
    is_private: album.is_private,
    is_upload_disabled: album.is_upload_disabled,
    is_unlisted: album.is_unlisted,
    is_games_disabled: album.is_games_disabled,
    thumbnail: undefined,
  })

  function updateAlbumDatas(data: Partial<Album>) {
    setAlbumData({
      ...albumData,
      ...data,
    })
  }

  function onFileSelect(e: any) {
    if (!e.target.files || !e.target.files[0]) {
      updateAlbumDatas({
        thumbnail: undefined,
      })
    }

    updateAlbumDatas({
      thumbnail: e.target.files[0],
    })
  }

  function onSubmitClick(e: any) {
    e.preventDefault()
    e.stopPropagation()
    onAlbumSaveClick()
  }

  function onAlbumSaveClick() {
    console.log('thumbnail', albumData)
    onSubmit(albumData)
  }

  return <>
    <form className='flex flex-col gap-3' onSubmit={onSubmitClick}>
      <label className="form-control w-full max-w-xs">
        <span className="label-text">{t('common.name')}</span>
        <Input maxLength={100} value={albumData.title} required
               onChange={e => updateAlbumDatas({title: e.target.value})}/>
      </label>
      <label className="form-control w-full max-w-xs">

        <span className="label-text">{t('common.previewImage')}</span>
        <div className='mt-1'>
          <div className='flex flex-row gap-5 items-center'>
            {(albumData.thumbnail || album.thumbnail) &&
              <div className='w-[5rem] h-[5rem] mb-2 bg-cover bg-center rounded-full'
                // @ts-ignore*
                   style={{backgroundImage: `url(${albumData.thumbnail ? URL.createObjectURL(albumData.thumbnail) : album.thumbnail})`}}></div>}
            {/*{albumData.thumbnail && <div className='w-[5rem] h-[5rem] mb-2' src={''''''''''}></div>}*/}
            {/*{album.thumbnail && !albumData.thumbnail && <img className='w-[5rem] h-[5rem] mb-2' src={album.thumbnail}/>}*/}
            {/*{ (album.thumbnail || albumData.thumbnail !== null) && <Button onClick={onPreviewImageRemove} color='neutral' size='sm'>Bild entfernen</Button> }*/}
          </div>
          {/*<Button color='neutral' size='sm' onClick={}>Auswählen</Button>*/}
          <FileInput className='w-full' accept='image/jpeg,image/png' onInput={onFileSelect}/>
        </div>
      </label>

      <label className="form-control w-full max-w-xs">
        <span className="label-text">{t('common.description')}</span>
        <Textarea maxLength={1024} value={albumData.description}
                  onChange={e => updateAlbumDatas({description: e.target.value})}/>
      </label>
      <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_games_disabled}
                    onChange={() => updateAlbumDatas({is_games_disabled: !albumData.is_games_disabled})}></Checkbox>
          <div>
            {t('common.showGames')}
          </div>
        </div>
      </label>
      <h3 className='font-semibold text-xl'>{t('common.privacy')}</h3>
      <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_private}
                    onChange={() => updateAlbumDatas({is_private: !albumData.is_private})}></Checkbox>
          <div>
            {t('common.guestsCanSeeAll')}
            <p className='text-sm mt-1'>
              {t('common.guestsCanSeeAllDesc')}
            </p>
          </div>
        </div>
      </label>
      <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_upload_disabled}
                    onChange={() => updateAlbumDatas({is_upload_disabled: !albumData.is_upload_disabled})}></Checkbox>
          <div>
            {t('common.canUpload')}
          </div>
        </div>
      </label>
      {!album.is_primary && <label>
        <div className='flex flex-row gap-3 items-center'>
          <Checkbox color='primary' checked={!albumData.is_unlisted}
                    onChange={() => updateAlbumDatas({is_unlisted: !albumData.is_unlisted})}></Checkbox>
          <div>
            {t('common.thisAlbumIsListed')}
            <p className='text-sm mt-1'>
              {t('common.thisAlbumIsListedDesc')}
            </p>
          </div>
        </div>
      </label>}
      {originalAlbum && <>
        <Collapse className='collapse-arrow bg-secondary' open={deleteAlbumCollapse('delete').open}>
          <Collapse.Title className='px-0 font-semibold' onClick={deleteAlbumCollapse('delete').onClick}>
            <h3 className='font-semibold text-xl'>{t('common.deleteAlbumQuestion')}</h3>
          </Collapse.Title>
          <Collapse.Content className='px-0'>
            <div className='flex flex-col gap-5'>
              {originalAlbum.is_primary && <p>{t('common.cantDeleteMainAlbum')}</p>}
              {!originalAlbum.is_primary && <>
                <p>{t('common.cantOnlyDeleteEmptyAlbums')}</p>
                <div className='flex'>
                  <Badge className='flex-1 py-1 h-[unset]' variant='outline' size='lg' color='error'
                         onClick={() => deleteModalRef.current!.showModal()}>{t('common.deleteAlbum')}</Badge>
                </div>
              </>}
            </div>
          </Collapse.Content>
        </Collapse>
      </>}

      <Button disabled={isSaving} loading={isSaving} className='mt-5' color='primary'>{t('common.save')}</Button>
    </form>
    {originalAlbum && <Modal ref={deleteModalRef} responsive>
      <Modal.Header className='text-center'>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconTrash size='20'/>
          </div>
          {t('common.deleteAlbum')}
        </div>
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body className='text-center flex flex-col gap-3'>
        <div className='flex flex-col gap-2 items-center'>
          <p className='mb-5'>
            {t('common.rlyDeleteAlbum')}
          </p>
          <Button disabled={isDeleting} loading={isDeleting} className='px-5' color='error'
                  onClick={() => onDeleteConfirm!(originalAlbum)}>{t('common.delete')}</Button>
          <form method="dialog">
            <Button disabled={isDeleting} color='ghost'>{t('common.cancel')}</Button>
          </form>
        </div>

      </Modal.Body>
    </Modal> }
  </>
}

function ProfileSettings() {
  const {profile} = useProfile()
  const [displayName, setDisplayName] = useState(profile!.display_name)
  const [isSaving, setIsSaving] = useState(false)
  const {updateProfile} = useClient()
  const queryClient = useQueryClient()
  const { collapse: outerCollapse } = useCollapses()

  async function onProfileSaveClick() {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    try {
      await updateProfile(profile!.username, {display_name: displayName})
      await queryClient.invalidateQueries({
        queryKey: ['profile'],
      })
    } catch (e) {
      alert('Error saving profile ' + JSON.stringify(e))
    } finally {
      setIsSaving(false)
    }
  }

  return <>
    <h2 className='text-3xl font-serif my-4 mb-5'>{t('common.myProfile')}</h2>
    <Collapse className='collapse-arrow bg-secondary' open={outerCollapse('name').open}>
      <Collapse.Title className='font-semibold' onClick={outerCollapse('name').onClick}>
        {t('common.name')}
      </Collapse.Title>
      <Collapse.Content>
        <div className='flex flex-col gap-3'>
          <Input value={displayName} onChange={e => setDisplayName(e.target.value)}/>
          <Button disabled={isSaving} loading={isSaving} className='mt-5' color='primary'
                  onClick={onProfileSaveClick}>
            {t('common.save')}
          </Button>
        </div>
      </Collapse.Content>
    </Collapse>
  </>
}

interface ThemeDetailsKeyData {
  display: string
  property: string
  color: string
}

function ThemeSettings({ drive }: { drive: HeyDrive }) {
  const [isSavingTheme, setIsSavingTheme] = useState(false)
  const { setTheme } = useHeyTheme()

  const driveDefaults = getDriveDefaultsFor(drive.drive_type)
  const defaultTheme = {
    primary: '#' + driveDefaults.color_primary,
    neutral: '#' + driveDefaults.color_neutral,
    content: '#' + driveDefaults.color_content,
  }
  const [currentTheme, setCurrentTheme] = useState<ThemeDetails>(drive.color_primary ? {
    primary: '#' + drive.color_primary,
    neutral: '#' + drive.color_neutral,
    content: '#' + drive.color_content,
  } : defaultTheme)
  const { updateDrive } = useClient()
  const queryClient = useQueryClient()
  const modalRef = useRef<HTMLDialogElement>(null)
  const [currentModalData, setCurrentModalData] = useState<ThemeDetailsKeyData | undefined>(undefined)

  async function onSaveClick() {
    if (isSavingTheme) {
      return
    }

    setIsSavingTheme(true)
    try {
      await updateDrive(drive.id, {
        color_primary: currentTheme.primary.slice(1),
        color_neutral: currentTheme.neutral.slice(1),
        color_content: currentTheme.content.slice(1),
      })
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e: any) {
      console.error('error saving theme', e)
      alert('Error savin theme' + JSON.stringify(e.response))
    } finally {
      setIsSavingTheme(false)
    }
  }

  function onColorChangeClick(data: ThemeDetailsKeyData) {
    setCurrentModalData(data)
    modalRef.current?.showModal()
  }

  function onThemeSelect(theme: ThemeDetails) {
    setTheme(
      theme.primary,
      theme.neutral,
      theme.content,
    )
    setCurrentTheme(theme)
  }

  const themePreviews = defaultThemes.map((theme, i) => <ThemeColors key={i} theme={theme} onClick={() => onThemeSelect(theme)} />)

  return <div className='flex flex-col gap-3'>
    <Modal ref={modalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {currentModalData?.display}
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col items-center'>
          {currentModalData && <ChromePicker
            disableAlpha
            // @ts-ignore
            color={currentTheme[currentModalData.property]}
              onChange={(c) => onThemeSelect({
                ...currentTheme,
                [currentModalData?.property]: c.hex,
              })}
            />
          }
          <Button disabled={isSavingTheme} loading={isSavingTheme} className='mt-5' color='primary' onClick={() => modalRef.current?.close()}>Fertig</Button>
        </div>
      </Modal.Body>
    </Modal>
    <h3 className='text-xl font-semibold'>{t('common.chooseExampleImages')}</h3>
    <div className='flex flex-row gap-2'>
      { themePreviews }
    </div>
    <h3 className='text-xl font-semibold'>{t('common.adaptColors')}</h3>
    <div className='flex flex-row gap-3'>
      <ThemeColors
        theme={currentTheme}
        big
        onPrimaryClick={() => onColorChangeClick({ display: t('common.primary'), property: 'primary', color: currentTheme.primary })}
        onNeutralClick={() => onColorChangeClick({ display: t('common.background'), property: 'neutral', color: currentTheme.neutral })}
        onContentClick={() => onColorChangeClick({ display: t('common.content'), property: 'content', color: currentTheme.content })}
      />
      <div className='inline-flex flex-col gap-2 p-2 rounded'>
        <div className='rounded-full h-[2rem] flex items-center' onClick={() => onColorChangeClick({ display: t('common.primary'), property: 'primary', color: currentTheme.primary })}>{t('common.primary')}</div>
        <div className='rounded-full h-[2rem] flex items-center' onClick={() => onColorChangeClick({ display: t('common.background'), property: 'neutral', color: currentTheme.neutral })}>{t('common.background')}</div>
        <div className='rounded-full h-[2rem] flex items-center' onClick={() => onColorChangeClick({ display: t('common.content'), property: 'content', color: currentTheme.content })}>{t('common.content')}</div>
      </div>
      <div className='inline-flex flex-col gap-2 p-2 rounded text-[#040404]'>
        <div className='rounded-full h-[2rem] py-[0.2rem] flex items-center underline cursor-pointer lowercase' onClick={() => onColorChangeClick({ display: t('common.primary'), property: 'primary', color: currentTheme.primary })}>🎨 {t('common.select')}</div>
        <div className='rounded-full h-[2rem] py-[0.2rem] flex items-center underline cursor-pointer lowercase' onClick={() => onColorChangeClick({ display: t('common.background'), property: 'neutral', color: currentTheme.neutral })}>🎨 {t('common.select')}</div>
        <div className='rounded-full h-[2rem] py-[0.2rem] flex items-center underline cursor-pointer lowercase' onClick={() => onColorChangeClick({ display: t('common.content'), property: 'content', color: currentTheme.content })}>🎨 {t('common.select')}</div>
      </div>
    </div>
    <Button disabled={isSavingTheme} loading={isSavingTheme} className='mt-5' color='primary'
            onClick={onSaveClick}>{t('common.save')}</Button>
  </div>
}

function FullDownloadSection({ drive, open }: { drive: HeyDrive, open: boolean }) {
  const { getFullDownloads, getFullDownloadRequests, requestFullDownload, deleteFullDownload } = useClient()
  const { data: fullDownloads, isLoading: isFullDownloadLoading, refetch: refetchDownloads } = useQuery({
    queryKey: ['drive', drive.id, 'downloads'],
    queryFn: async () => {
      const res = await getFullDownloads(drive)
      return res.data
    },
    enabled: open,
  })

  const { data: fullDownloadRequests, isLoading: isRequestsLoading, refetch: refetchRequests } = useQuery({
    queryKey: ['drive', drive.id, 'downloadRequests'],
    queryFn: async () => {
      const res = await getFullDownloadRequests(drive)
      return res.data
    },
    enabled: open,
  })

  const nonDoneDownloads = fullDownloadRequests && fullDownloadRequests.filter((downloadRequest: FullDownloadRequest) => downloadRequest.status !== FullDownloadRequestStatus.DONE)

  async function onRequestFullDownloadClick() {
    try {
      const downloadRequestRes = await requestFullDownload(drive)
      console.log('DownloadRequestResponse', downloadRequestRes)
      await refetchRequests()
    } catch (e) {
      if (e instanceof AxiosError) {
        if (!e.response) {
          captureException(e, 'requesting full download')
          alert('Failed requesting download. Retry later.')
        } else {
          if (e.response.status === 400) {
            const failureResponse = JSON.parse(e.response.data)
            const errorCode = failureResponse.error_code
            if (errorCode === 'download_cooldown') {
              alert(t('common.downloadCooldown', { hours: 2 }))
              return
            }

            if (errorCode === 'download_already_requested') {
              alert(t('common.downloadAlreadyRequested'))
              return
            }

            if (errorCode === 'download_already_exists') {
              alert(t('common.downloadAlreadyExists'))
              return
            }

            console.error('Error creating request', errorCode)
          } else {
            captureException(e, 'requesting full download')
            alert('Failed requesting download. Retry later.')
          }
        }
      } else {
        alert('Failed requesting download. Retry later.')
        captureException(e, 'requesting full download')
      }
      console.error('nahh', e)
    }
  }

  function getStatusTextForRequest(status: FullDownloadRequestStatus) {
    if (status === FullDownloadRequestStatus.NEEDS_CONFIRMATION) {
      return t('common.downloadRequestNeedsConfirmation')
    }

    if (status === FullDownloadRequestStatus.TODO || status === FullDownloadRequestStatus.IN_PROGRESS) {
      return t('common.downloadRequestInProgress')
    }

    if (status === FullDownloadRequestStatus.FAILED) {
      return t('common.downloadRequestFailed')
    }
  }

  async function onDeleteClick(download: FullDownload) {
    if (confirm(t('common.deleteThisFullDownload'))) {
      try {
        await deleteFullDownload(drive, download)
        await refetchDownloads()
      } catch (e) {
        console.error('Error deleting download', e)
        captureException(e, 'Error deleting full download')
      }
    }
  }

  return <div className='flex flex-col gap-3'>
    <p>{t('common.youWantFullDownload')}</p>
    <h3 className='text-xl font-semibold'>{t('common.requestFullDownload')}</h3>
    { isFullDownloadLoading && isRequestsLoading && <Loading color='primary' /> }
    { fullDownloads && fullDownloads.length > 0 && <div className='flex flex-col gap-2 justify-center items-center w-full'>
      { fullDownloads.map((download: FullDownload) => <div className='bg-neutral rounded p-2 flex flex-col gap-2 w-full items-center'>
        <div className='relative w-full text-center'>
          <div className='absolute top-0 right-2 cursor-pointer' onClick={() => onDeleteClick(download)} color='ghost'><IconTrash /></div>
          <h4 className='font-bold'>{ new Date(download.created_at).toLocaleString() }</h4>
        </div>
        {download.file_size && <div>
          <span><b>{t('common.fileSize')}:</b> {(download.file_size/1024/1024/1024).toFixed(4)} GB</span>
        </div>}
        <div className='text-center my-2'>
          <a className='bg-primary text-neutral rounded p-2 font-semibold' href={download.file}>{t('common.download')}</a>
        </div>
      </div>) }
    </div> }
    { nonDoneDownloads && <div className='flex flex-col gap-2 justify-center items-center w-full'>
      { nonDoneDownloads.map((downloadRequest: FullDownloadRequest) => <div
        className='bg-neutral rounded p-2 flex flex-col gap-2 w-full items-center'>
        <h4 className='font-bold'>{new Date(downloadRequest.created_at).toLocaleString()}</h4>
        <div className='text-center my-2'>
          <span><b>{t('common.status')}:</b> {getStatusTextForRequest(downloadRequest.status)}</span>
        </div>
      </div>)}
    </div>}
    <Button color='primary' onClick={onRequestFullDownloadClick}>{t('common.createFullDownload')}</Button>
  </div>
}

function Liveshow({ drive }: { drive: HeyDrive }) {
  const navigate = useNavigate()

  return <div className='flex flex-col gap-3'>
    <h3 className='text-xl font-semibold'>{t('common.liveshow')}</h3>
    <p>{t('common.liveshowExplanation')}</p>
    <p>{t('common.liveshowExplanation2')}</p>
    <p>{t('common.liveshowExplanation3')}</p>
    <Button color='primary' onClick={() => navigate(`/d/${drive.id}/liveshow`)}>{t('common.toLiveshow')}</Button>
  </div>
}

function HelpSupport({drive}: { drive: HeyDrive }) {
  const langInfos = getCurrentLanguageInfos()
  return <div className='flex flex-col gap-3 items'>
    <h3 className='text-xl font-semibold'>{t('common.helpAndSupport')}</h3>
    <p>
      <Trans i18nKey='common.helpAndSupportQuestion' />
    </p>
    <div className='flex flex-col gap-5 items-center mt-4'>
      <div>
        <Button color='success' className='text-[white] p-0 px-2'>
          <a className='w-full h-full flex flex-row items-center px-2 justify-center gap-1'
             href='https://wa.me/496987000170' target='_blank'
          >
            <IconBrandWhatsapp/>
            {t('common.openWhatsapp')}
          </a>
        </Button>
      </div>
      <p><b>E-Mail:</b> <a
        href={`mailto:${langInfos.email}?subject=${t('common.supportSubject', { id: drive.id })}&body=${t('common.supportBody')} `} className='text-primary underline'>{langInfos.email}</a></p>
    </div>
  </div>
}

function QRCodeSettings({drive}: { drive: HeyDrive }) {
  const [fg, setFg] = useState(`#${drive.qr_fg}`)
  const [bg, setBg] = useState(`#${drive.qr_bg}`)
  const [shape, setShape] = useState(drive.qr_shape)
  const [isSaving, setIsSaving] = useState(false)
  const { updateQrCode } = useClient()
  const queryClient = useQueryClient()

  async function onSaveClick() {
    if (isSaving) {
      return
    }

    setIsSaving(true)
    try {
      await updateQrCode(drive.id, {
        qr_fg: fg.substring(1),
        qr_bg: bg.substring(1),
        qr_shape: shape,
      })
      await queryClient.invalidateQueries({
        queryKey: ['drive', drive.id],
      })
    } catch (e: any) {
      console.error('error saving color', e)
      captureException(e, 'saving QR Code', () => ({ fg, bg, shape }))
      alert('Error savin color' + JSON.stringify(e.response))
    } finally {
      setIsSaving(false)
    }
  }

  return <div className='flex flex-col gap-3 items overflow-visible'>
    <h3 className='text-xl font-semibold'>{t('common.yourCurrentQrCode')}</h3>
    {
      drive.qr_code_image && <img src={drive.qr_code_image} className='w-[10rem]'/>
    }
    {
      !drive.qr_code_image &&
      <p>Noch nicht verfügbar. Bitte speichere deinen QR-Code ab, damit er erneut generiert wird.</p>
    }
    <h3 className='text-xl font-semibold mt-3'>{t('common.designQRCode')}</h3>
    <QRCodeDesigner fg={fg} setFg={setFg} bg={bg} setBg={setBg} shape={shape} setShape={setShape} svgBannerUrl='/fridaysnap-qrcode-banner.svg' shapes={{ [QRCodeShape.Rounded]: '/fridaysnap-qrcode.svg', [QRCodeShape.Square]: '/fridaysnap-qrcode-square.svg' }} />
    <Button onClick={onSaveClick} disabled={isSaving} loading={isSaving} color='primary'>{t('common.save')}</Button>
  </div>
}
