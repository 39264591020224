import { FilesCollection, makeKey } from '@/stores/useFiles.ts'
import React, { Context, createContext, useContext, useMemo } from 'react'
import { Album } from '@/models/album.ts'
import { useAlbums } from '@/stores/useAlbums.ts'
import { InfiniteQueryObserverResult, useInfiniteQuery } from '@tanstack/react-query'
import { useClient } from '@/stores/useClient.ts'
import { AlbumFile } from '@/models/albumFile.ts'
import { useApi } from '@/stores/useApi.ts'
import { useUploadQueue } from '@/stores/useUploadQueue.ts'
import { useDrives } from '@/stores/useDrives.ts'
import { getDemoFilesForDriveType } from '@/controller/demo.ts'
import { useParams } from 'react-router-dom'
import { useFileFilter } from '@/contexts/fileFilter.tsx'

export interface FilesContext {
  currentFilesCollection: FilesCollection | null
  currentAlbum: Album | null
  currentAlbumId: string | null
  hasNextPage?: boolean
  fetchNextPage: () => Promise<InfiniteQueryObserverResult>
  isLoading: boolean
}

export const FilesContext = createContext<FilesContext | null>(null)

export function FilesProvider({ children, filesFilter, albumId: propAlbumId, FilesContext: PropFilesContext, uploadedFilesFilter }: { children: React.ReactNode, albumId?: string, filesFilter?: Record<string, string>, FilesContext?: Context<FilesContext | null>, uploadedFilesFilter?: (file: AlbumFile) => boolean  }) {
  const { albums } = useAlbums()
  const { drives, currentDrive } = useDrives()
  const api = useApi()
  const { getFiles } = useClient()
  const { albumId: albumIdFromParams } = useParams<{albumId?: string}>()
  const { uploadedFiles, deletedFiles } = useUploadQueue()
  const albumId = propAlbumId || albumIdFromParams || (currentDrive && drives[currentDrive.id]?.albums?.find(a => a.is_primary)?.id || null)
  const { fileFilter } = useFileFilter()
  // const [uploadedFiles, setUploadedFiles] = useState<Array<AlbumFile>>([])

  // const { album, appendFiles } = useFiles()
  const currentAlbum = albumId ? (albums[albumId] || null ) : null

  // @ts-ignore
  const query = useInfiniteQuery({
    enabled: currentAlbum != null,
    queryKey: currentAlbum ? makeKey(currentAlbum.id, filesFilter || fileFilter) : [null],
    async queryFn({ pageParam }) {
      if (pageParam) {
        // @ts-ignore
        return (await api.get(pageParam)).data
      }

      return (await getFiles(currentAlbum!, filesFilter || fileFilter)).data
    },
    getNextPageParam(page) {
      // @ts-ignore
      return page.next
    },
  })

  const fetchedFiles = useMemo(() => {
    if (!query.data) {
      return []
    }

    const data = query.data
    const files: Array<AlbumFile> = [
      // @ts-ignore
      ...(data ? data.pages.flatMap(page => page.results) : [])
    ]

    let filteredUploadedFiles: Array<AlbumFile> = []
    if (currentAlbum) {
      filteredUploadedFiles = uploadedFiles[currentAlbum.id] || []
      if (uploadedFilesFilter) {
        filteredUploadedFiles = filteredUploadedFiles.filter(uploadedFilesFilter)
      }

      filteredUploadedFiles = filteredUploadedFiles.filter(file => !deletedFiles.has(file.id) && !files.find(f => f.id === file.id))
    }

    return [
      ...filteredUploadedFiles,
      ...files.filter(file => !deletedFiles.has(file.id)),
      ...(
        currentDrive?.is_demo ? getDemoFilesForDriveType(currentDrive.drive_type) : []
      ),
    ]
  }, [currentAlbum, deletedFiles, query.data, uploadedFiles, currentDrive?.is_demo]);

  // function addUploadedFiles(album: FridaySnap, files: Array<AlbumFile>) {
  //   const currentlyUploaded = uploadedFiles[album.id] || []
  //
  //   setUploadedFiles({
  //     ...uploadedFiles,
  //     [album.id]: [...currentlyUploaded, ...files],
  //   })
  // }
  //
  // function addDeletedFiles(files: Array<AlbumFile>) {
  //   const newFiles = new Set(deletedFiles)
  //
  //   for (const file of files) {
  //     newFiles.add(file.id)
  //   }
  //
  //   setDeletedFiles(newFiles)
  //   // setUploadedFiles(uploadedFiles.filter(uploadedFile => newFiles.has(uploadedFile.id)))
  // }

  const MyContext = PropFilesContext || FilesContext

  return <MyContext.Provider value={{
    currentFilesCollection: {
      files: fetchedFiles,
    },
    currentAlbum,
    currentAlbumId: albumId,
    hasNextPage: query.hasNextPage,
    fetchNextPage: query.fetchNextPage,
    isLoading: query.isLoading,
    // uploadedFiles,
    // addUploadedFiles,
    // deletedFiles,
    // addDeletedFiles,
  }}>
    { children }
  </MyContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export function useFilesContext() {
  return useContext(FilesContext)
}
