import { create } from 'zustand'
import { produce } from 'immer'
import { Album } from '@/models/album.ts'

export interface VisitedAlbumsDriveData {
  albumIds: Array<string>
}

interface VisitedAlbumsState {
  drives: Record<string, VisitedAlbumsDriveData>
}

interface VisitedAlbumsActions {
  addVisitedAlbumIds: (driveId: string, albumId: string) => void
  getVisibleAlbums: (driveId: string, albums: Array<Album>, isOwner: boolean) => Array<Album>
  hasVisitedAlbumId: (driveId: string, albumId: string) => boolean
}

export const useVisitedAlbumIds = create<VisitedAlbumsState & VisitedAlbumsActions>((set, getState) => ({
  drives: {},
  hasVisitedAlbumId(driveId: string, albumId: string) {
    const albumIds = getState().drives[driveId]?.albumIds || []

    return albumIds.includes(albumId)
  },
  addVisitedAlbumIds(driveId: string, albumId: string) {
    set(produce((state: VisitedAlbumsState) => {
      const visitedData = state.drives[driveId] || {albumIds: []}

      if (!visitedData.albumIds.includes(albumId)) {
        visitedData.albumIds.push(albumId)
      }

      state.drives = {
        ...state.drives,
        [driveId]: {
          albumIds: [...visitedData.albumIds],
        },
      }
    }))
  },
  getVisibleAlbums(driveId: string, albums: Array<Album>, isOwner: boolean) {
    return albums.filter(album => isOwner || (!album.is_unlisted || (getState().hasVisitedAlbumId(driveId, album.id))))
  },
}))
