import React, { createContext, useContext, useState } from 'react'
import { AlbumFileType } from '@/models/albumFileType.ts'

export interface FileFilterOptions {
  sort?: 'created-at' | '-created-at' | 'file-creation' | '-file-creation'
  media?: 'all' | AlbumFileType.IMAGE | AlbumFileType.VIDEO
}

export const DefaultFileFilter: FileFilterOptions = {
  sort: '-created-at',
  media: 'all',
}

interface FileFilterContext {
  fileFilter?: FileFilterOptions
  setFileFilter: (fileFilter?: FileFilterOptions) => void
}

export const FileFilterContext = createContext<FileFilterContext>({
  fileFilter: undefined,
  setFileFilter: () => {
    throw 'No FileFilterContext'
  },
})

export function FileFilterProvider({ children }: { children: React.ReactNode }) {
  const [fileFilter, setFileFilter] = useState<FileFilterOptions | undefined>(undefined)

  return <FileFilterContext.Provider value={{
    fileFilter,
    setFileFilter,
  }}>
    { children }
  </FileFilterContext.Provider>
}

export function useFileFilter() {
  return useContext(FileFilterContext)
}

export function getURLParamsForFilter(filter: FileFilterOptions): Array<Array<string>> {
  const params: Array<Array<string>> = []
  if (filter.media && filter.media !== DefaultFileFilter.media) {
    params.push(['file_type', filter.media])
  }

  if (filter.sort && filter.sort !== DefaultFileFilter.sort) {
    params.push(['o', filter.sort])
  }

  return params
}
