import { FileSelectionType } from '@/hooks/useFileSelection.tsx'
import { Button, Modal } from 'react-daisyui'
import { IconArrowsMove, IconDotsCircleHorizontal, IconTrash } from '@tabler/icons-react'
import { useRef, useState } from 'react'
import { useClient } from '@/stores/useClient.ts'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { makeKey } from '@/stores/useFiles.ts'
import AlbumSelection from '@/components/AlbumSelection.tsx'
import { useDrives } from '@/stores/useDrives.ts'
import { Album } from '@/models/album.ts'
import { useUploadQueue } from '@/stores/useUploadQueue.ts'
import { t } from 'i18next'
import { getURLParamsForFilter, useFileFilter } from '@/contexts/fileFilter.tsx'

export default function FileSelection({ fileSelection }: { fileSelection: FileSelectionType }) {
  const { currentAlbum: album } = useFilesContext()!
  const { drives, currentDrive } = useDrives()
  const { isAllSelected, setIsAllSelected, setIsSelecting } = fileSelection
  const selectedFiles = fileSelection.getSelectedAlbumFileIds()
  const areActionsDisabled = !isAllSelected && selectedFiles.length <= 0
  const [isDeleting, setIsDeleting] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const deleteModalRef = useRef<HTMLDialogElement>(null)
  const moveModalRef = useRef<HTMLDialogElement>(null)
  const { deleteFiles, updateFiles } = useClient()
  const queryClient = useQueryClient()
  const [targetMoveAlbum, setTargetMoveAlbum] = useState<Album | null>(null)
  const { removeUploadedFilesForAlbum } = useUploadQueue()
  const {fileFilter} = useFileFilter()

  function getFilterFromSelection(): Array<Array<string>> {
    if (isAllSelected) {
      return []
    }

    if (selectedFiles.length <= 0) {
      throw 'no selection'
    }

    return [['ids', selectedFiles.join(',')]]
  }

  function onMoveClick() {
    setTargetMoveAlbum(null)
    moveModalRef.current!.showModal()
  }

  function onDeleteClick() {
    deleteModalRef.current!.showModal()
  }

  async function onDeleteConfirm() {
    if (isDeleting) {
      return
    }

    let filterFromSelection: Array<Array<string>>
    try {
      filterFromSelection = getFilterFromSelection()
    } catch (e) {
      alert('Cannot create filter')
      return
    }
    let filterFromFileFilter = fileFilter ? getURLParamsForFilter(fileFilter) : []

    const filter = [...filterFromSelection, ...filterFromFileFilter]

    setIsDeleting(true)
    try {
      await deleteFiles(album!, filter)
      await queryClient.invalidateQueries({
        queryKey: makeKey(album!.id),
      })
      removeUploadedFilesForAlbum(album!)
      setIsSelecting(false)
      deleteModalRef.current!.close()
    } catch (e: any) {
      alert('Error deleting files: ' + JSON.stringify(e.response))
      return
    } finally {

      setIsDeleting(false)
    }
  }

  async function onMoveConfirm() {
    if (isMoving || targetMoveAlbum == null) {
      return
    }

    let filterFromSelection: Array<Array<string>>
    try {
      filterFromSelection = getFilterFromSelection()
    } catch (e) {
      alert('Cannot create filter')
      return
    }
    let filterFromFileFilter = fileFilter ? getURLParamsForFilter(fileFilter) : []

    const filter = [...filterFromSelection, ...filterFromFileFilter]

    setIsMoving(true)
    try {
      await updateFiles(album!, filter, { album: targetMoveAlbum.id })
      await queryClient.invalidateQueries({
        queryKey: makeKey(album!.id),
      })
      await queryClient.invalidateQueries({
        queryKey: makeKey(targetMoveAlbum!.id),
      })
      removeUploadedFilesForAlbum(album!)
      setIsSelecting(false)
      moveModalRef.current!.close()
    } catch (e: any) {
      alert('Error deleting files: ' + JSON.stringify(e.response))
      return
    } finally {
      setIsMoving(false)
    }
  }

  return <div className='fixed bottom-0 left-0 w-full z-50 bg-secondary border-t-4 border-primary shadow-lg'>
    <div className='container px-4 mx-auto py-3 flex items-center justify-center flex-row h-[4rem]'>
      <div className='flex-1 flex justify-start items-center font-semibold leading-5'>
        {/*<div className={'border-4 rounded-full px-3 py-1 ' + (isAllSelected ? 'bg-primary' : 'bg-neutral text-primary border-primary')}>*/}
        {/*  Alles*/}
        {/*</div>*/}

        <Button
          className='rounded-full border-4'
          variant={isAllSelected ? undefined : 'outline'}
          color={'primary'}
          size='sm'
          onClick={() => setIsAllSelected(!isAllSelected)}
        >
          {t('common.all')}
        </Button>
      </div>
      <div className='flex-1 flex justify-center items-center text-center font-semibold leading-5 text-primary'>
        { isAllSelected && t('common.allSelected') }
        { !isAllSelected && (selectedFiles.length <= 0 ? t('common.selectObjects') : `${t('common.selected', {count: selectedFiles.length})}`) }
      </div>
      <div className='flex-1 flex justify-end items-center text-center font-semibold leading-5 gap-2'>
        <Button disabled={areActionsDisabled} className='px-1 border-0' color='primary' variant='outline' onClick={onDeleteClick}>
          <IconTrash />
        </Button>
        { drives[currentDrive!.id].albums.length > 1 && <Button disabled={areActionsDisabled} className='px-1 border-0' color='primary' variant='outline' onClick={onMoveClick}>
          <IconDotsCircleHorizontal />
        </Button> }
      </div>
    </div>
    <Modal ref={deleteModalRef}>
      <Modal.Header>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconTrash size='20'/>
          </div>
          {t('common.deleteObjects')}
        </div>
        <form method="dialog">
          <p className='mt-3 text-center'>
            { isAllSelected && t('common.deleteAllAlbumRly') }
            { !isAllSelected && t('common.deleteObjectsRly') }
          </p>
          <button disabled={isDeleting} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col gap-2 items-center'>
          <Button disabled={isDeleting} loading={isDeleting} className='px-5' color='error' onClick={onDeleteConfirm}>
            {t('common.delete')}
          </Button>
          <form method="dialog">
            <Button disabled={isDeleting} color='ghost'>
              {t('common.cancel')}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
    <Modal ref={moveModalRef}>
      <Modal.Header>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconArrowsMove size='20'/>
          </div>
          {t('common.moveObjects')}
        </div>
        <form method="dialog">
          <p className='mt-3 text-center'>
            {t('common.moveObjectsWhere')}
          </p>
          <button disabled={isMoving} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col gap-2 items-center'>
          <AlbumSelection externalAlbums={drives[currentDrive!.id].albums.filter(a => album!.id !== a.id)} drive={null} selectedAlbum={targetMoveAlbum} setSelectedAlbum={setTargetMoveAlbum} />
          <Button disabled={targetMoveAlbum == null || isMoving} loading={isMoving} className='px-5 mt-5' color='primary' onClick={onMoveConfirm}>
            {t('common.moveObjects')}
          </Button>
          <form method="dialog">
            <Button disabled={isMoving} color='ghost'>
              {t('common.cancel')}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  </div>
}
