import { Button, Input } from 'react-daisyui'
import { useClient } from '@/stores/useClient.ts'
import { useState } from 'react'
import { useProfile } from '@/stores/useAuth.ts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { HeyDriveType } from '@/models/drive.ts'

export default function DemoPage() {
  const { createDemo } = useClient()
  const [isCreatingDemo, setIsCreatingDemo] = useState(false)
  const [email, setEmail] = useState('')
  const { setAuthenticationToken, setProfile } = useProfile()
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [searchParams] = useSearchParams()
  const driveType: HeyDriveType | undefined = searchParams.get('type') as HeyDriveType || undefined

  async function onSubmit(e: any) {
    e.preventDefault()
    e.stopPropagation()

    if (isCreatingDemo) {
      return
    }

    setIsCreatingDemo(true)

    try {
      const res = await createDemo(email, driveType)
      setProfile(res.data.profile)
      setAuthenticationToken(res.headers['ha-token'])

      const driveId = res.data.drive
      navigate(`/d/${driveId}`)
    } catch (e: any) {
      console.error('Demo Error', e)
      if (e.response) {
        if (e.response.status === 400) {
          if (e.response.data && e.response.data.includes('invalid_data') && e.response.data.includes('email')) {
            setError('Bitte eine gültige E-Mail Adresse angeben')
          }
          if (e.response.data && e.response.data === 'email taken') {
            setError('Diese E-Mail wurde bereits verwendet.')
          }
          console.log('data', e.response.data)
        }

      }
      // alert('Error creating demo: ' + JSON.stringify(e.response))
    } finally {
      setIsCreatingDemo(false)
    }
  }

  // @ts-ignore
  const driveTypeImage = {
    [HeyDriveType.BIRTHDAY]: '/demoX/app-screenshot-geburtstag.jpeg',
    [HeyDriveType.COMPANY]: '/demoX/app-screenshot-firmenfeier.jpeg',
  }[driveType] || '/demoX/app-screenshot-hochzeit.jpeg'

  return <div className='bg-cover bg-top h-full' style={{ backgroundImage: `url(${driveTypeImage})` }}>
    <div className="w-full h-full bg-[black] bg-opacity-40">
      <div className='w-full h-full flex items-center justify-center'>
        <div className='bg-neutral p-4 m-3 rounded-xl text-center flex flex-col gap-4 shadow'>
          <h1 className='text-xl font-semibold'>Kostenlose Demo</h1>
          <p>Sofortigen Demo-Zugang zu FridaySnap erhalten</p>
          <form onSubmit={onSubmit} className='flex flex-col gap-5'>
            <Input placeholder='E-Mail'  required value={email} onChange={e => setEmail(e.target.value)} />
            { error && <p className='text-error text-sm'>{ error }</p> }
            <div>
              <Button color='primary' className=''>
                Demo aufrufen
              </Button>
            </div>
          </form>
        </div>
        {/*<div className="container mx-auto bg-primary">*/}
        {/*  snrtnrt*/}
        {/*</div>*/}
      </div>
    </div>
  </div>
}
