import { HeyDriveType } from '@/models/drive.ts'

interface DriveDefaults {
  previewPicture: string
  color_primary: string
  color_neutral: string
  color_content: string
}

const driveDefaults: Record<HeyDriveType, DriveDefaults> = {
  [HeyDriveType.WEDDING]: {
    previewPicture: '/demoX/demo-wedding-preview.jpg',
    color_primary: '781140',
    color_neutral: 'f5f1ef',
    color_content: '040404',
  },
  [HeyDriveType.BIRTHDAY]: {
    previewPicture: '/demoX/birthday-preview.jpg',
    color_primary: 'f89584',
    color_neutral: 'f8f2ec',
    color_content: '2f2d2d',
  },
  [HeyDriveType.BABY_SHOWER]: {
    previewPicture: '/baby-shower-preview-picture.jpg',
    color_primary: 'CCC094',
    color_neutral: 'F1F1EF',
    color_content: '403D3D',
  },
  [HeyDriveType.COMPANY]: {
    previewPicture: '/demoX/company-preview.jpeg',
    color_primary: '41444B',
    color_neutral: 'DFD8C8',
    color_content: '52575D',
  },
}

export function getDriveDefaultsFor(driveType: HeyDriveType) {
  return driveDefaults[driveType]
}
