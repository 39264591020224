import { Outlet, useParams } from 'react-router-dom'
import NavigationTabs from '@/components/NavigationTabs.tsx'
import { FilesProvider } from '@/contexts/filesContext.tsx'
import { useEffect } from 'react'
import { useVisitedAlbumIds } from '@/stores/useVisitedAlbums.ts'
import { FileFilterProvider } from '@/contexts/fileFilter.tsx'

export default function GenericDrivePage() {
  const {driveId, albumId} = useParams<{driveId?: string, albumId?: string}>()
  const {drives, hasVisitedAlbumId, addVisitedAlbumIds} = useVisitedAlbumIds()

  useEffect(() => {
    if (albumId && driveId && !hasVisitedAlbumId(driveId, albumId)) {
      addVisitedAlbumIds(driveId, albumId)
    }
  }, [albumId, drives, hasVisitedAlbumId, addVisitedAlbumIds]);

  return <div className='pb-[3rem] bg-neutral h-full'>
    <FileFilterProvider>
      <FilesProvider>
        <Outlet/>
        <NavigationTabs/>
      </FilesProvider>
    </FileFilterProvider>
  </div>
}
