import { useFilesContext } from '@/contexts/filesContext.tsx'
import { useRef, useState } from 'react'
import { useClient } from '@/stores/useClient.ts'
import { useQueryClient } from '@tanstack/react-query'
import { useProfile } from '@/stores/useAuth.ts'
import { useNavigate } from 'react-router-dom'
import HeyGame from '@/models/heyGame.ts'
import DriveHeader from '@/components/DriveHeader.tsx'
import { useMyGameFilesContext } from '@/contexts/myGameFilesContext.tsx'
import { Button, Collapse, Input, Modal } from 'react-daisyui'
import { IconCheck, IconChevronRight, IconPencil, IconPlus, IconTrash } from '@tabler/icons-react'
import { t } from 'i18next'
import LoadBig from '@/components/LoadBig/LoadBig.tsx'
import { useDrives } from '@/stores/useDrives.ts'

export default function GamesOverviewPage() {
  const { currentDrive } = useDrives()
  const { currentAlbum: album } = useFilesContext()!
  const [editingGames, setEditingGames] = useState(false)
  // const [isAddingGame, setIsAddingGame] = useState(false)
  const [isSavingAddingGame, setIsSavingAddingGame] = useState(false)
  const [isSavingUpdatingGame, setIsSavingUpdatingGame] = useState(false)
  const [isDeletingGame, setIsDeletingGame] = useState(false)
  const addModalRef = useRef<HTMLDialogElement>(null)
  const updateModalRef = useRef<HTMLDialogElement>(null)
  const deleteModalRef = useRef<HTMLDialogElement>(null)
  const [newGameChallenge, setNewGameChallenge] = useState('')
  const [editChallengeText, setEditChallengeText] = useState('')
  const [editChallengeId, setEditChallengeId] = useState('')
  const [deleteChallengeId, setDeleteChallengeId] = useState('')
  const { createGame, updateGame, deleteGame } = useClient()
  const queryClient = useQueryClient()
  const { profile } = useProfile()
  const navigate = useNavigate()


  const canEditGame = profile != null && currentDrive && currentDrive.author === profile.username

  if (!album) {
    return <LoadBig />
  }

  const gamesComponents = album.games.map(game => <Game
    key={game.id}
    game={game}
    onClick={onGameClick}
    isEditing={editingGames}
    onEditClick={onEditGamePress}
    onDeleteClick={onDeleteGamePress}
  />)

  function onGameClick(game: HeyGame) {
    navigate(`/d/${currentDrive!.id}/${album!.id}/game/${game.id}`)
  }

  function onAddGamePress() {
    setNewGameChallenge('')
    addModalRef.current?.showModal()
  }

  function onEditGamePress(game: HeyGame) {
    setEditChallengeText(game.challenge)
    setEditChallengeId(game.id)
    updateModalRef.current?.showModal()
  }

  function onDeleteGamePress(game: HeyGame) {
    setDeleteChallengeId(game.id)
    deleteModalRef.current?.showModal()
  }

  async function onSaveEditPress(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (isSavingUpdatingGame) {
      return
    }

    setIsSavingUpdatingGame(true)

    try {
      await updateGame(editChallengeId, editChallengeText)
      await queryClient.invalidateQueries({
        queryKey: ['drive', currentDrive!.id],
      })
      updateModalRef.current?.close()
    } catch (e) {
      console.error('error creating game', e)
      // @ts-ignore
      alert('Error updating game: ' + JSON.stringify(e.response))
    } finally {
      setIsSavingUpdatingGame(false)
    }
  }


  async function onConfirmDeletePress(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (isDeletingGame) {
      return
    }

    setIsDeletingGame(true)

    try {
      await deleteGame(deleteChallengeId)
      await queryClient.invalidateQueries({
        queryKey: ['drive', currentDrive!.id],
      })
      deleteModalRef.current?.close()
    } catch (e) {
      console.error('error creating game', e)
      // @ts-ignore
      alert('Error deleting game: ' + JSON.stringify(e.response))
    } finally {
      setIsDeletingGame(false)
    }
  }

  async function onSaveAddPress(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (isSavingAddingGame) {
      return
    }

    setIsSavingAddingGame(true)

    try {
      await createGame(album!, newGameChallenge)
      // TODO: Shouldn't this be an album invalidate?
      await queryClient.invalidateQueries({
        queryKey: ['drive', currentDrive!.id],
      })
      addModalRef.current?.close()
    } catch (e) {
      console.error('error creating game', e)
      // @ts-ignore
      alert('Error creating game: ' + JSON.stringify(e.response))
    } finally {
      setIsSavingAddingGame(false)
    }
  }

  return <div>
    <DriveHeader />
    <Modal ref={addModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconPlus size='20' />
          </div>
          {t('games.add')}
        </div>
        <form method="dialog">
          <button disabled={isSavingAddingGame} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕</button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col gap-5 items-center'>
          {/*<p>Aufgabe</p>*/}
          <form className='flex-1 mx-auto max-w-[20rem] w-full flex flex-col items-center gap-5' onSubmit={onSaveAddPress}>
            <label className="form-control w-full max-w-xs">
              <span className="label-text">{t('games.task')}</span>
              <Input maxLength={200} value={newGameChallenge} placeholder={t('games.task')} disabled={isSavingAddingGame}
                     onChange={e => setNewGameChallenge(e.target.value)}/>
            </label>
            <Button className='w-full' color='primary' disabled={isSavingAddingGame} loading={isSavingAddingGame}>{t('common.save')}</Button>
          </form>
          <div className='flex flex-col gap-3'>
            <form className='w-full' method="dialog">
              <Button wide color='ghost' disabled={isSavingAddingGame}>{t('common.cancel')}</Button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal ref={updateModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconPencil size='20' />
          </div>
          {t('games.update')}
        </div>
        <form method="dialog">
          <button disabled={isSavingUpdatingGame} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕</button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col gap-5 items-center'>
          {/*<p>Aufgabe</p>*/}
          <form className='flex-1 mx-auto max-w-[20rem] w-full flex flex-col items-center gap-5' onSubmit={onSaveEditPress}>
            <label className="form-control w-full max-w-xs">
              <span className="label-text">{t('games.task')}</span>
              <Input minLength={1} maxLength={200} value={editChallengeText} placeholder={t('games.task')} disabled={isSavingUpdatingGame}
                     onChange={e => setEditChallengeText(e.target.value)}/>
            </label>
            <Button className='w-full' color='primary' disabled={isSavingUpdatingGame} loading={isSavingUpdatingGame}>{t('common.save')}</Button>
          </form>
          <div className='flex flex-col gap-3'>
            <form className='w-full' method="dialog">
              <Button wide color='ghost' disabled={isSavingUpdatingGame}>{t('common.cancel')}</Button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal ref={deleteModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        <div className='flex flex-row gap-2 justify-center'>
          <div className='bg-primary text-neutral rounded-full p-1'>
            <IconTrash size='20' />
          </div>
          Spiel löschen
        </div>
        <form method="dialog">
          <p className='mt-3 text-sm text-center'>Alle zugehörigen Bilder werden dadurch nicht gelöscht. Sie werden weiterhin im Album bleiben, allerdings ohne das gelöschte Spiel.</p>
          <button disabled={isDeletingGame} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕</button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col gap-5 items-center'>
          <form className='flex-1 mx-auto max-w-[20rem] w-full flex flex-col items-center gap-5' onSubmit={onConfirmDeletePress}>
            <Button className='w-full' color='error' disabled={isDeletingGame} loading={isDeletingGame}>Löschen</Button>
          </form>
          <div className='flex flex-col gap-3'>
            <form className='w-full' method="dialog">
              <Button wide color='ghost' disabled={isDeletingGame}>Abbrechen</Button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <div className='container px-4 mx-auto flex flex-col gap-3 items-center'>
      <h2 className='font-serif text-3xl mb-3'>{t('common.games')}</h2>
      <div className='w-full max-w-[30rem]'>
        <div className='flex flex-col gap-4'>
          { canEditGame && <Collapse open={editingGames} className='bg-secondary'>
            <Collapse.Title onClick={() => setEditingGames(!editingGames)}>
              <div className='text-xl flex flex-row gap-2 font-semibold justify-center'>
                <div className='bg-primary text-neutral rounded-full p-1'>
                  {/*<IconPlus size='20' />*/}
                  <IconPencil size='20' />
                </div>
                {t('games.adapt')}
              </div>
            </Collapse.Title>
            <Collapse.Content>
              <div className='flex flex-col items-center gap-4'>
                <p className='text-sm text-center'>{t('games.savedWhere')} ({album.title})</p>
                <Button className='flex flex-row gap-3 bg-primary text-neutral rounded-full p-1 px-4' onClick={onAddGamePress}>
                  <IconPlus size='20'/>
                  {t('games.add')}
                </Button>
              </div>
            </Collapse.Content>
          </Collapse> }
          {gamesComponents}
        </div>
      </div>
    </div>
  </div>
}

function Game({ game, onClick, isEditing, onEditClick, onDeleteClick }: { game: HeyGame, onClick: (game: HeyGame) => void, isEditing: boolean, onEditClick: (game: HeyGame) => void, onDeleteClick: (game: HeyGame) => void }) {
  const { currentFilesCollection } = useMyGameFilesContext()!
  const { profile } = useProfile()

  const entry = profile != null && currentFilesCollection!.files.find(file => file.game && file.game.id === game.id && file.author === profile.username)
  const hasPlayedGame = !!entry

  return <div className='flex flex-row gap-3'>
    <div
      className={
        'flex-1 bg-primary text-neutral rounded-full py-3 flex flex-row cursor-pointer relative ' + (isEditing ? 'px-3' : 'px-5')
      }
      onClick={isEditing ? undefined : () => onClick(game)}
    >
      { !isEditing && hasPlayedGame && <IconCheck className='absolute left-4 top-[50%] translate-y-[-50%]' /> }
      <span className='flex-1 font-semibold text-center px-2'>
        { game.challenge }
      </span>
      { !isEditing && <IconChevronRight className='absolute right-3 top-[50%] translate-y-[-50%]' /> }
    </div>
    { isEditing && <div className='flex flex-row gap-3'>
      <Button className='flex flex-row gap-2 px-3' color='secondary' onClick={() => onEditClick(game)}>
        <IconPencil />
      </Button>
      <Button className='flex flex-row gap-2 px-2 text-error' color='ghost' onClick={() => onDeleteClick(game)}>
        <IconTrash />
      </Button>
    </div> }
  </div>
}
