import { useAlbums } from '@/stores/useAlbums'
import { useQuery } from '@tanstack/react-query'
import { Outlet, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useApi } from '@/stores/useApi'
import LoadBig from '@/components/LoadBig/LoadBig'
import { useDrives } from '@/stores/useDrives.ts'
import { useProfile } from '@/stores/useAuth.ts'
import { HeyProfile } from '@/models/profile.ts'
import { HeyDrive } from '@/models/drive.ts'
import { useHeyTheme } from '@/contexts/heyTheme.tsx'
import NameChangedModal from '@/components/NameChangedModal.tsx'
import { getDriveDefaultsFor } from '@/controller/driveDefaults.ts'

export function DrivePage() {
  return (
    <>
      <DrivePageContent />
    </>
  );
}

function DrivePageContent() {
  const { driveId } = useParams<{ driveId: string }>()
  const { setAlbum } = useAlbums()
  const { drives, setDrive, setCurrentDrive } = useDrives()
  const { setProfile, authenticationToken, setAuthenticationToken } = useProfile()

  const drive = drives[driveId!]
  const api = useApi()
  const { style: driveStyle, setTheme, color_primary } = useHeyTheme()

  useEffect(() => {
    if (!drive) {
      return
    }

    if (!(drive.color_primary && drive.color_neutral && drive.color_content)) {
      const driveDefaults = getDriveDefaultsFor(drive.drive_type)
      setTheme(
        driveDefaults.color_primary,
        driveDefaults.color_neutral,
        driveDefaults.color_content,
      )
      return
    }

    setTheme(
      drive.color_primary,
      drive.color_neutral,
      drive.color_content,
    )
  }, [setTheme, drive?.color_primary, drive?.color_neutral, drive?.color_content]);

  const { data: driveData, isLoading } = useQuery({
    queryKey: ['drive', driveId],
    queryFn: () => api.get(`api/drive/${driveId}`),
  })

  const { data: dataProfile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      try {
        return await api.get(`api/profile/mine`)
      } catch (e) {
        // @ts-ignore
        if (e && e && e.response && e.response.status === 401) {
          setAuthenticationToken(null)
          return null
        }

        throw e
      }
    },
    enabled: authenticationToken != null,
  })

  useEffect(() => {
    if (driveData) {
      console.log('data', driveData)
      const fetchedDrive: HeyDrive = driveData.data
      setDrive(fetchedDrive)
      setCurrentDrive(fetchedDrive)
      for (const album of fetchedDrive.albums) {
        setAlbum(album)
      }

      // if (!currentAlbumId) {
      //   const primaryAlbum = fetchedDrive.albums.find(a => a.is_primary)
      //   if (primaryAlbum) {
      //     setCurrentAlbum(primaryAlbum)
      //   }
      // }
    }
  }, [driveData, setAlbum, setCurrentDrive, setDrive]);

  useEffect(() => {
    if (dataProfile) {
      console.log('dataProfile', dataProfile)
      const fetchedProfile: HeyProfile = dataProfile.data
      setProfile(fetchedProfile)
    }
  }, [dataProfile, setProfile]);


  if (isLoading || !drive) {
    return <LoadBig />
  }

  return <>
    { color_primary && <meta name='theme-color' content={`#${color_primary}`} /> }
    <style> {driveStyle} </style>
    <Outlet/>
    <NameChangedModal/>
  </>
}
