import { Tabs } from 'react-daisyui'
import { useDrives } from '@/stores/useDrives.ts'
import { useProfile } from '@/stores/useAuth.ts'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import UploadProgress from '@/components/UploadProgress.tsx'
import { t } from 'i18next'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { HeyDriveType } from '@/models/drive.ts'

export default function NavigationTabs() {
  const { driveId } = useParams<{ driveId: string }>()
  const { currentAlbumId, currentAlbum } = useFilesContext()!
  const { pathname } = useLocation()
  const { drives } = useDrives()
  const { profile } = useProfile()
  const navigate = useNavigate()
  const drive = drives[driveId!]
  const isOwner = profile?.username === drive.author
  const shallShowYesIDo = !isOwner && !drive.is_demo && !drive.is_hide_yes && drive.drive_type !== HeyDriveType.COMPANY
  const shallShowGames = currentAlbum != null && !currentAlbum.is_games_disabled

  const activeTab = useMemo(() => {
    if (pathname.includes('/settings')) {
      return 'settings'
    }

    if (pathname.includes('/game')) {
      return 'game'
    }

    if (pathname.endsWith('/yes')) {
      return 'yes'
    }

    return 'album'
  }, [pathname])
  if (!drive) {
    return <></>
  }

  const tabs = [
    <Tabs.Tab className='flex-1' key={'album'} active={activeTab === 'album'} onClick={() => navigate(`/d/${drive.id}/${currentAlbumId}`)}>
      {t('common.album')}
    </Tabs.Tab>,
  ]

  if (shallShowGames) {
    tabs.push(<Tabs.Tab className='flex-1' key={'game'} active={activeTab === 'game'} onClick={() => navigate(`/d/${drive.id}/${currentAlbumId}/game`)}>
      {t('common.games')}
    </Tabs.Tab>)
  }

  if (profile && drive.author === profile.username) {
    tabs.push(<Tabs.Tab className='flex-1' key={'settings'} onClick={() => navigate(`/d/${drive.id}/${currentAlbumId}/settings`)} active={activeTab === 'settings'}>
      {t('common.settings')}
    </Tabs.Tab>)
  }

  if (shallShowYesIDo) {
    tabs.push(<Tabs.Tab className='flex-1' key={'yes'} onClick={() => navigate(`/d/${drive.id}/${currentAlbumId}/yes`)} active={activeTab === 'yes'}>
      {t('common.yesIDo')}
    </Tabs.Tab>)
  }

  return <div className='fixed bottom-0 left-0 w-full'>
    <div className="container mx-auto relative bg-neutral">
      <UploadProgress />
      <Tabs variant='boxed' className='bg-secondary flex'>
        {tabs}
      </Tabs>
    </div>
  </div>
}
