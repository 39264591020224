import { create } from 'zustand'
import { produce } from 'immer'
import { AlbumFile } from '@/models/albumFile.ts'
import { Album } from '@/models/album.ts'
import { FileFilterOptions } from '@/contexts/fileFilter.tsx'

export interface FilesCollection {
  files: Array<AlbumFile>
}

interface UseFilesState {
  album: Record<string, FilesCollection>
}

interface UseFilesActions {
  setFiles: (album: Album, files: Array<AlbumFile>) => void
  appendFiles: (album: Album, files: Array<AlbumFile>) => void
}

export const useFiles = create<UseFilesState & UseFilesActions>((set) => ({
  album: {},
  setFiles(album: Album, files: Array<AlbumFile>) {
    set(produce(state => {
      state.albums[album.id] = files
    }))
  },
  appendFiles(album: Album, files: Array<AlbumFile>) {
    set(produce(state => {
      const currentFiles = state.albums[album.id]
      state.albums[album.id] = [
        ...(currentFiles ? currentFiles : []),
        ...files,
      ]
    }))
  },
}))

export function makeKey(albumId: string, filter?: Record<string, string> | FileFilterOptions) {
  const key = ['album', albumId, 'files']

  if (filter) {
    key.push(JSON.stringify(filter))
  }

  return key
}
