import { AlbumFile } from '@/models/albumFile.ts'
import { AlbumFileType } from '@/models/albumFileType.ts'
import { HeyDriveType } from '@/models/drive.ts'

// export const demoFiles: Array<AlbumFile> = [
//   {id: '3VSkzpnh43armcfWydgwwe.JPG', game: null, file: `/demoX/img/3VSkzpnh43armcfWydgwwe.JPG`, thumbnail: `/demoX/thumbs/3VSkzpnh43armcfWydgwwe.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/3VSkzpnh43armcfWydgwwe.JPG`, is_demo: true},
//   {id: '3wUSysHn8rRSSiAKvFU2iE.JPG', game: null, file: `/demoX/img/3wUSysHn8rRSSiAKvFU2iE.JPG`, thumbnail: `/demoX/thumbs/3wUSysHn8rRSSiAKvFU2iE.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/3wUSysHn8rRSSiAKvFU2iE.JPG`, is_demo: true},
//   {id: '4xjm4qtr8QZc44ZinryEBs.JPG', game: null, file: `/demoX/img/4xjm4qtr8QZc44ZinryEBs.JPG`, thumbnail: `/demoX/thumbs/4xjm4qtr8QZc44ZinryEBs.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/4xjm4qtr8QZc44ZinryEBs.JPG`, is_demo: true},
//   {id: '8m2QyECa7Y7kk8MxNeWd6z.JPG', game: null, file: `/demoX/img/8m2QyECa7Y7kk8MxNeWd6z.JPG`, thumbnail: `/demoX/thumbs/8m2QyECa7Y7kk8MxNeWd6z.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/8m2QyECa7Y7kk8MxNeWd6z.JPG`, is_demo: true},
//   {id: '9Dr6aS5235h5Goa7UKsTXv.JPG', game: null, file: `/demoX/img/9Dr6aS5235h5Goa7UKsTXv.JPG`, thumbnail: `/demoX/thumbs/9Dr6aS5235h5Goa7UKsTXv.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/9Dr6aS5235h5Goa7UKsTXv.JPG`, is_demo: true},
//   {id: '9TPUgTdiV9HcTJF7gsjoXG.JPG', game: null, file: `/demoX/img/9TPUgTdiV9HcTJF7gsjoXG.JPG`, thumbnail: `/demoX/thumbs/9TPUgTdiV9HcTJF7gsjoXG.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/9TPUgTdiV9HcTJF7gsjoXG.JPG`, is_demo: true},
//   {id: '84FsSv3KP7t7jF5mwnAeS2.JPG', game: null, file: `/demoX/img/84FsSv3KP7t7jF5mwnAeS2.JPG`, thumbnail: `/demoX/thumbs/84FsSv3KP7t7jF5mwnAeS2.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/84FsSv3KP7t7jF5mwnAeS2.JPG`, is_demo: true},
//   {id: 'bHN9sqtjJ2a8Wa8kbfJ8d6.JPG', game: null, file: `/demoX/img/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG`, thumbnail: `/demoX/thumbs/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG`, is_demo: true},
//   {id: 'CFjKgEtGbkrZpehRsJJrvF.JPG', game: null, file: `/demoX/img/CFjKgEtGbkrZpehRsJJrvF.JPG`, thumbnail: `/demoX/thumbs/CFjKgEtGbkrZpehRsJJrvF.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/CFjKgEtGbkrZpehRsJJrvF.JPG`, is_demo: true},
//   {id: 'e6iyqHJDE8kaPAHjCEPFc2.JPG', game: null, file: `/demoX/img/e6iyqHJDE8kaPAHjCEPFc2.JPG`, thumbnail: `/demoX/thumbs/e6iyqHJDE8kaPAHjCEPFc2.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/e6iyqHJDE8kaPAHjCEPFc2.JPG`, is_demo: true},
//   {id: 'fHY7yC2Em2NeCPFkH9RDTA.JPG', game: null, file: `/demoX/img/fHY7yC2Em2NeCPFkH9RDTA.JPG`, thumbnail: `/demoX/thumbs/fHY7yC2Em2NeCPFkH9RDTA.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/fHY7yC2Em2NeCPFkH9RDTA.JPG`, is_demo: true},
//   {id: 'h3Jz5vzzTvgmwVyFSAfCDa.JPG', game: null, file: `/demoX/img/h3Jz5vzzTvgmwVyFSAfCDa.JPG`, thumbnail: `/demoX/thumbs/h3Jz5vzzTvgmwVyFSAfCDa.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/h3Jz5vzzTvgmwVyFSAfCDa.JPG`, is_demo: true},
//   {id: 'HLKDdXoTqdEEF6TrrcGStr.JPG', game: null, file: `/demoX/img/HLKDdXoTqdEEF6TrrcGStr.JPG`, thumbnail: `/demoX/thumbs/HLKDdXoTqdEEF6TrrcGStr.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/HLKDdXoTqdEEF6TrrcGStr.JPG`, is_demo: true},
//   {id: 'iXSH5Nv7fQeyvnF2mmQaWz.JPG', game: null, file: `/demoX/img/iXSH5Nv7fQeyvnF2mmQaWz.JPG`, thumbnail: `/demoX/thumbs/iXSH5Nv7fQeyvnF2mmQaWz.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/iXSH5Nv7fQeyvnF2mmQaWz.JPG`, is_demo: true},
//   {id: 'JDx7zf3xgycyPb3E3XD9zq.JPG', game: null, file: `/demoX/img/JDx7zf3xgycyPb3E3XD9zq.JPG`, thumbnail: `/demoX/thumbs/JDx7zf3xgycyPb3E3XD9zq.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/JDx7zf3xgycyPb3E3XD9zq.JPG`, is_demo: true},
//   {id: 'jHRZHhdPCNmUDAexNtw3q6.JPG', game: null, file: `/demoX/img/jHRZHhdPCNmUDAexNtw3q6.JPG`, thumbnail: `/demoX/thumbs/jHRZHhdPCNmUDAexNtw3q6.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/jHRZHhdPCNmUDAexNtw3q6.JPG`, is_demo: true},
//   {id: 'JJop8u2o4eQRmUtip4McUb.JPG', game: null, file: `/demoX/img/JJop8u2o4eQRmUtip4McUb.JPG`, thumbnail: `/demoX/thumbs/JJop8u2o4eQRmUtip4McUb.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/JJop8u2o4eQRmUtip4McUb.JPG`, is_demo: true},
//   {id: 'LgB4DchCZ9LBa5GYDakQq9.JPG', game: null, file: `/demoX/img/LgB4DchCZ9LBa5GYDakQq9.JPG`, thumbnail: `/demoX/thumbs/LgB4DchCZ9LBa5GYDakQq9.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/LgB4DchCZ9LBa5GYDakQq9.JPG`, is_demo: true},
//   {id: 'oPbmtpoTYnWZrGyovJz2Bd.JPG', game: null, file: `/demoX/img/oPbmtpoTYnWZrGyovJz2Bd.JPG`, thumbnail: `/demoX/thumbs/oPbmtpoTYnWZrGyovJz2Bd.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/oPbmtpoTYnWZrGyovJz2Bd.JPG`, is_demo: true},
//   {id: 'PAb8j5Zr2b8JkZHgyeabwa.JPG', game: null, file: `/demoX/img/PAb8j5Zr2b8JkZHgyeabwa.JPG`, thumbnail: `/demoX/thumbs/PAb8j5Zr2b8JkZHgyeabwa.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/PAb8j5Zr2b8JkZHgyeabwa.JPG`, is_demo: true},
//   {id: 'PRxJPRz9fhfW7QWBa8cqC.JPG', game: null, file: `/demoX/img/PRxJPRz9fhfW7QWBa8cqC.JPG`, thumbnail: `/demoX/thumbs/PRxJPRz9fhfW7QWBa8cqC.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/PRxJPRz9fhfW7QWBa8cqC.JPG`, is_demo: true},
//   {id: 'Qed7zTTYmtJdMYLgmy79BU.JPG', game: null, file: `/demoX/img/Qed7zTTYmtJdMYLgmy79BU.JPG`, thumbnail: `/demoX/thumbs/Qed7zTTYmtJdMYLgmy79BU.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/Qed7zTTYmtJdMYLgmy79BU.JPG`, is_demo: true},
//   {id: 'RyFdgXP6anS64BnnFrz8km.JPG', game: null, file: `/demoX/img/RyFdgXP6anS64BnnFrz8km.JPG`, thumbnail: `/demoX/thumbs/RyFdgXP6anS64BnnFrz8km.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/RyFdgXP6anS64BnnFrz8km.JPG`, is_demo: true},
//   {id: 'SNoYnrtzzHLPTemMHT9mZ3.JPG', game: null, file: `/demoX/img/SNoYnrtzzHLPTemMHT9mZ3.JPG`, thumbnail: `/demoX/thumbs/SNoYnrtzzHLPTemMHT9mZ3.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/SNoYnrtzzHLPTemMHT9mZ3.JPG`, is_demo: true},
//   {id: 'UVtvD4PzAKRnXNYRSJEHbL.JPG', game: null, file: `/demoX/img/UVtvD4PzAKRnXNYRSJEHbL.JPG`, thumbnail: `/demoX/thumbs/UVtvD4PzAKRnXNYRSJEHbL.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/UVtvD4PzAKRnXNYRSJEHbL.JPG`, is_demo: true},
//   {id: 'X3x8teKXi3RRGnie7nFCZV.JPG', game: null, file: `/demoX/img/X3x8teKXi3RRGnie7nFCZV.JPG`, thumbnail: `/demoX/thumbs/X3x8teKXi3RRGnie7nFCZV.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/X3x8teKXi3RRGnie7nFCZV.JPG`, is_demo: true},
//   {id: 'yZovTZaNdh5Dut29hSNYj.JPG', game: null, file: `/demoX/img/yZovTZaNdh5Dut29hSNYj.JPG`, thumbnail: `/demoX/thumbs/yZovTZaNdh5Dut29hSNYj.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/yZovTZaNdh5Dut29hSNYj.JPG`, is_demo: true},
//   {id: 'ZA67EL3C2XBoKym6DrozfL.JPG', game: null, file: `/demoX/img/ZA67EL3C2XBoKym6DrozfL.JPG`, thumbnail: `/demoX/thumbs/ZA67EL3C2XBoKym6DrozfL.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/ZA67EL3C2XBoKym6DrozfL.JPG`, is_demo: true},
// ]

export const weddingDemoFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/wedding/8978 Large.jpeg',
    detail: '/demoX/wedding/8978 Large.jpeg',
    thumbnail: '/demoX/wedding/8978 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '2',
    author: '-1',
    game: null,
    file: '/demoX/wedding/0259 Large.jpeg',
    detail: '/demoX/wedding/0259 Large.jpeg',
    thumbnail: '/demoX/wedding/0259 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/wedding/0313 Large.jpeg',
    detail: '/demoX/wedding/0313 Large.jpeg',
    thumbnail: '/demoX/wedding/0313 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/wedding/5238 Large.jpeg',
    detail: '/demoX/wedding/5238 Large.jpeg',
    thumbnail: '/demoX/wedding/5238 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '13',
    author: '-1',
    game: null,
    file: '/demoX/wedding/5256 Large.jpeg',
    detail: '/demoX/wedding/5256 Large.jpeg',
    thumbnail: '/demoX/wedding/5256 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/wedding/3181 Large.jpeg',
    detail: '/demoX/wedding/3181 Large.jpeg',
    thumbnail: '/demoX/wedding/3181 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/wedding/4189 Large.jpeg',
    detail: '/demoX/wedding/4189 Large.jpeg',
    thumbnail: '/demoX/wedding/4189 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/wedding/4523 Large.jpeg',
    detail: '/demoX/wedding/4523 Large.jpeg',
    thumbnail: '/demoX/wedding/4523 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/wedding/4553 Large.jpeg',
    detail: '/demoX/wedding/4553 Large.jpeg',
    thumbnail: '/demoX/wedding/4553 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/wedding/4561 Large.jpeg',
    detail: '/demoX/wedding/4561 Large.jpeg',
    thumbnail: '/demoX/wedding/4561 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/wedding/4565 Large.jpeg',
    detail: '/demoX/wedding/4565 Large.jpeg',
    thumbnail: '/demoX/wedding/4565 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/wedding/5058 Large.jpeg',
    detail: '/demoX/wedding/5058 Large.jpeg',
    thumbnail: '/demoX/wedding/5058 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/wedding/5187 Large.jpeg',
    detail: '/demoX/wedding/5187 Large.jpeg',
    thumbnail: '/demoX/wedding/5187 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '14',
    author: '-1',
    game: null,
    file: '/demoX/wedding/5821 Large.jpeg',
    detail: '/demoX/wedding/5821 Large.jpeg',
    thumbnail: '/demoX/wedding/5821 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '15',
    author: '-1',
    game: null,
    file: '/demoX/wedding/6609 Large.jpeg',
    detail: '/demoX/wedding/6609 Large.jpeg',
    thumbnail: '/demoX/wedding/6609 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '16',
    author: '-1',
    game: null,
    file: '/demoX/wedding/6667 Large.jpeg',
    detail: '/demoX/wedding/6667 Large.jpeg',
    thumbnail: '/demoX/wedding/6667 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '17',
    author: '-1',
    game: null,
    file: '/demoX/wedding/7539 Large.jpeg',
    detail: '/demoX/wedding/7539 Large.jpeg',
    thumbnail: '/demoX/wedding/7539 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '18',
    author: '-1',
    game: null,
    file: '/demoX/wedding/7563 Large.jpeg',
    detail: '/demoX/wedding/7563 Large.jpeg',
    thumbnail: '/demoX/wedding/7563 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '19',
    author: '-1',
    game: null,
    file: '/demoX/wedding/7571 Large.jpeg',
    detail: '/demoX/wedding/7571 Large.jpeg',
    thumbnail: '/demoX/wedding/7571 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '20',
    author: '-1',
    game: null,
    file: '/demoX/wedding/8229 Large.jpeg',
    detail: '/demoX/wedding/8229 Large.jpeg',
    thumbnail: '/demoX/wedding/8229 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '21',
    author: '-1',
    game: null,
    file: '/demoX/wedding/8243 Large.jpeg',
    detail: '/demoX/wedding/8243 Large.jpeg',
    thumbnail: '/demoX/wedding/8243 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '22',
    author: '-1',
    game: null,
    file: '/demoX/wedding/8375 Large.jpeg',
    detail: '/demoX/wedding/8375 Large.jpeg',
    thumbnail: '/demoX/wedding/8375 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  },
]

const birthdayFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/birthday/9045 Large.jpeg',
    detail: '/demoX/birthday/9045 Large.jpeg',
    thumbnail: '/demoX/birthday/9045 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '2',
    author: '-1',
    game: null,
    file: '/demoX/birthday/0756 Large.jpeg',
    detail: '/demoX/birthday/0756 Large.jpeg',
    thumbnail: '/demoX/birthday/0756 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/birthday/0769 Large.jpeg',
    detail: '/demoX/birthday/0769 Large.jpeg',
    thumbnail: '/demoX/birthday/0769 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/birthday/1651 Large.jpeg',
    detail: '/demoX/birthday/1651 Large.jpeg',
    thumbnail: '/demoX/birthday/1651 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/birthday/2932 Large.jpeg',
    detail: '/demoX/birthday/2932 Large.jpeg',
    thumbnail: '/demoX/birthday/2932 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/birthday/4506 Large.jpeg',
    detail: '/demoX/birthday/4506 Large.jpeg',
    thumbnail: '/demoX/birthday/4506 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/birthday/5196 Large.jpeg',
    detail: '/demoX/birthday/5196 Large.jpeg',
    thumbnail: '/demoX/birthday/5196 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/birthday/5352 Large.jpeg',
    detail: '/demoX/birthday/5352 Large.jpeg',
    thumbnail: '/demoX/birthday/5352 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/birthday/5788 Large.jpeg',
    detail: '/demoX/birthday/5788 Large.jpeg',
    thumbnail: '/demoX/birthday/5788 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/birthday/7420 Large.jpeg',
    detail: '/demoX/birthday/7420 Large.jpeg',
    thumbnail: '/demoX/birthday/7420 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/birthday/7846 Large.jpeg',
    detail: '/demoX/birthday/7846 Large.jpeg',
    thumbnail: '/demoX/birthday/7846 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/birthday/8082 Large.jpeg',
    detail: '/demoX/birthday/8082 Large.jpeg',
    thumbnail: '/demoX/birthday/8082 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  }
]

const babyShowerFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/9040 Large.jpeg',
    detail: '/demoX/babyparty/9040 Large.jpeg',
    thumbnail: '/demoX/babyparty/9040 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/3350 Large.jpeg',
    detail: '/demoX/babyparty/3350 Large.jpeg',
    thumbnail: '/demoX/babyparty/3350 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/3474 Large.jpeg',
    detail: '/demoX/babyparty/3474 Large.jpeg',
    thumbnail: '/demoX/babyparty/3474 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/3580 Large.jpeg',
    detail: '/demoX/babyparty/3580 Large.jpeg',
    thumbnail: '/demoX/babyparty/3580 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/4654 Large.jpeg',
    detail: '/demoX/babyparty/4654 Large.jpeg',
    thumbnail: '/demoX/babyparty/4654 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/5858 Large.jpeg',
    detail: '/demoX/babyparty/5858 Large.jpeg',
    thumbnail: '/demoX/babyparty/5858 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '2',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/2222 Large.jpeg',
    detail: '/demoX/babyparty/2222 Large.jpeg',
    thumbnail: '/demoX/babyparty/2222 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/6650 Large.jpeg',
    detail: '/demoX/babyparty/6650 Large.jpeg',
    thumbnail: '/demoX/babyparty/6650 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/6906 Large.jpeg',
    detail: '/demoX/babyparty/6906 Large.jpeg',
    thumbnail: '/demoX/babyparty/6906 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/7400 Large.jpeg',
    detail: '/demoX/babyparty/7400 Large.jpeg',
    thumbnail: '/demoX/babyparty/7400 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/7722 Large.jpeg',
    detail: '/demoX/babyparty/7722 Large.jpeg',
    thumbnail: '/demoX/babyparty/7722 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/8338 Large.jpeg',
    detail: '/demoX/babyparty/8338 Large.jpeg',
    thumbnail: '/demoX/babyparty/8338 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '13',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/8564 Large.jpeg',
    detail: '/demoX/babyparty/8564 Large.jpeg',
    thumbnail: '/demoX/babyparty/8564 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '14',
    author: '-1',
    game: null,
    file: '/demoX/babyparty/8824 Large.jpeg',
    detail: '/demoX/babyparty/8824 Large.jpeg',
    thumbnail: '/demoX/babyparty/8824 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  },
]

const companyFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/company/0291 Large.jpeg',
    detail: '/demoX/company/0291 Large.jpeg',
    thumbnail: '/demoX/company/0291 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/company/2102 Large.jpeg',
    detail: '/demoX/company/2102 Large.jpeg',
    thumbnail: '/demoX/company/2102 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/company/7955 Large.jpeg',
    detail: '/demoX/company/7955 Large.jpeg',
    thumbnail: '/demoX/company/7955 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/company/6272 Large.jpeg',
    detail: '/demoX/company/6272 Large.jpeg',
    thumbnail: '/demoX/company/6272 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/company/5823 Large.jpeg',
    detail: '/demoX/company/5823 Large.jpeg',
    thumbnail: '/demoX/company/5823 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/company/2298 Large.jpeg',
    detail: '/demoX/company/2298 Large.jpeg',
    thumbnail: '/demoX/company/2298 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '2',
    author: '-1',
    game: null,
    file: '/demoX/company/0373 Large.jpeg',
    detail: '/demoX/company/0373 Large.jpeg',
    thumbnail: '/demoX/company/0373 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/company/3224 Large.jpeg',
    detail: '/demoX/company/3224 Large.jpeg',
    thumbnail: '/demoX/company/3224 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/company/3567 Large.jpeg',
    detail: '/demoX/company/3567 Large.jpeg',
    thumbnail: '/demoX/company/3567 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/company/3976 Large.jpeg',
    detail: '/demoX/company/3976 Large.jpeg',
    thumbnail: '/demoX/company/3976 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/company/5514 Large.jpeg',
    detail: '/demoX/company/5514 Large.jpeg',
    thumbnail: '/demoX/company/5514 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/company/7596 Large.jpeg',
    detail: '/demoX/company/7596 Large.jpeg',
    thumbnail: '/demoX/company/7596 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '13',
    author: '-1',
    game: null,
    file: '/demoX/company/8094 Large.jpeg',
    detail: '/demoX/company/8094 Large.jpeg',
    thumbnail: '/demoX/company/8094 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '14',
    author: '-1',
    game: null,
    file: '/demoX/company/8120 Large.jpeg',
    detail: '/demoX/company/8120 Large.jpeg',
    thumbnail: '/demoX/company/8120 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  } ,
  {
    id: '15',
    author: '-1',
    game: null,
    file: '/demoX/company/8592 Large.jpeg',
    detail: '/demoX/company/8592 Large.jpeg',
    thumbnail: '/demoX/company/8592 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE,
  },
]

const demoFilesForType: Record<HeyDriveType, Array<AlbumFile>> = {
  [HeyDriveType.WEDDING]: weddingDemoFiles,
  [HeyDriveType.BIRTHDAY]: birthdayFiles,
  [HeyDriveType.BABY_SHOWER]: babyShowerFiles,
  [HeyDriveType.COMPANY]: companyFiles,
}

export function getDemoFilesForDriveType(driveType: HeyDriveType) {
  return demoFilesForType[driveType]
}
